window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame || function(f){setTimeout(f, 1000/60)};

const BODY 	= document.body;
const APP 	= document.querySelector('#app');
const path 	= basePath();

function verifyMidia () {
	var w = window.innerWidth;
	var size = 'xs';

	if(w < 500) {
		size = 'xs';
	}

	if(w >= 500) {
		size = 'sm';
	}

	if(w >= 768) {
		size = 'md';
	}

	if(w >= 992) {
		size = 'lg';
	}

	if(w >= 1200) {
		size = 'xl';
	}

	window.midiaSize = size;

	return size;
}

verifyMidia();

(function(){
	var ja = false;

	$(window).resize(function() {
		if(ja) return;

		setTimeout(function() {
			ja = false;
		}, (1000/60));

		verifyMidia();
	})
})();

function basePath () {
	let p = '';

	if($('body').data('path')) {
		p = $('body').data('path') + '/';
	}

	return p;
}

