(function() {
	var $btn		= $('.mbl__controls__btn');
	var $mainTopo	= $('.topo__navigation');
	var $body		= $('body');

	$btn.click(function(e) {
		e.preventDefault();

		var ariaExpanded = $btn.attr('aria-expanded');

		$mainTopo.addClass('topo__navigation--shown');

		$btn
			.attr('aria-expanded', 'true')
			.addClass('mbl__controls--active');
		$body.append('<div class="bg__menu"></div>');

		$('.bg__menu')
			.fadeIn(600)
			.click(function () {
				$mainTopo.removeClass('topo__navigation--shown');

				$btn
					.attr('aria-expanded', 'false')
					.removeClass('mbl__controls--active');

				$('.bg__menu').fadeOut(600, function() {
					$(this).remove();
				});
			});
	});
})();
(function () {
	var $btn 		= $('.mbl__controls__search');
	var $mblSearch 	= $('.pesquisa__mbl');
	var $body		= $('body');

	$btn.click(function(e) {
		e.preventDefault();

		var ariaExpanded = $btn.attr('aria-expanded');

		$mblSearch.addClass('pesquisa__mbl--shown');

		$btn
			.attr('aria-expanded', 'true')
			.addClass('mbl__search--active');
		$body.append('<div class="bg__menu"></div');

		$('.bg__menu')
			.fadeIn(600)
			.click(function () {
				$mblSearch.removeClass('pesquisa__mbl--shown');

				$btn
					.attr('aria-expanded', 'false')
					.removeClass('mbl__search--active');

				$('.bg__menu').fadeOut(600, function() {
					$(this).remove();
				});
			});
	});
})();